import { css } from '@emotion/css';
import { palette } from '../core/constants';

export const styles = {
  blogPostPage: css`
    h1 {
      font-size: 2em;
      margin-bottom: 0.5em;
    }

    h2 {
      font-size: 1.25em;
      font-weight: bold;
      margin-top: 0.75em;
      margin-bottom: 0.25em;
    }

    p {
      margin-bottom: 0.5em;
    }

    a {
      color: ${palette.themePrimary};
    }

    ol,
    ul {
      padding-left: 2em;
      margin-bottom: 0.5em;

      > li {
        margin-bottom: 0.25em;
      }
    }
  `,
};
