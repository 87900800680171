import React from 'react';
import { useLocation } from '@reach/router';
import { Flex, Box, Heading } from '@chakra-ui/react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

export const ShareOnSocialMedia = () => {
  const location = useLocation();
  return (
    <Box mt={10}>
      <Heading as="h3" size="md" mb={2}>
        Share this post:
      </Heading>
      <Flex>
        <Box mr={2}>
          <FacebookShareButton url={`https://instatab.io${location.pathname}`}>
            <FacebookIcon borderRadius={12.5} size={36} />
          </FacebookShareButton>
        </Box>
        <Box mr={2}>
          <TwitterShareButton url={`https://instatab.io${location.pathname}`}>
            <TwitterIcon borderRadius={12.5} size={36} />
          </TwitterShareButton>
        </Box>
        <Box>
          <LinkedinShareButton url={`https://instatab.io${location.pathname}`}>
            <LinkedinIcon borderRadius={12.5} size={36} />
          </LinkedinShareButton>
        </Box>
      </Flex>
    </Box>
  );
};
