import * as React from 'react';

import { Link as GatsbyLink } from 'gatsby';
import { HeadFC } from 'gatsby';
import { SEO } from '../components/Seo';
import { PageLayout } from '../components/PageLayout';
import { graphql } from 'gatsby';
import { CONTAINER_SIZE, routes } from '../core/constants';
import { Container, Heading, Image, Link, Icon } from '@chakra-ui/react';
import { styles } from '../page-styles/blog-post.styles';
import { ShareOnSocialMedia } from '../components/ShareOnSocialMedia';
import { AiOutlineCaretLeft } from 'react-icons/ai';
import '../pages/index.css';
import { BlogPostPublishedAt } from './BlogPostPublishedAt';
import { BlogPostTags } from './BlogPostTags';

type BlogPost = {
  coverImg?: {
    childImageSharp: {
      gatsbyImageData: { images: { fallback: { src: string } } };
    };
  };
  summary: string;
  coverImgAlt?: string;
  publishedAt: string;
  slug: string;
  tags: string;
  title: string;
};

type BlogPostPageProps = {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: Omit<BlogPost, 'order'>;
    };
    allMarkdownRemark: {
      nodes: {
        frontmatter: BlogPost;
      }[];
    };
  };
};

const BlogPostPage: React.FC<BlogPostPageProps> = ({ data }) => {
  const { html } = data.markdownRemark;
  const { title, coverImgAlt, tags, publishedAt } =
    data.markdownRemark.frontmatter;
  const coverImgSrc =
    data.markdownRemark.frontmatter.coverImg?.childImageSharp.gatsbyImageData
      .images.fallback.src;

  return (
    <PageLayout>
      <Container maxW={CONTAINER_SIZE} py={10}>
        <Link
          display={'inline-flex'}
          alignItems={'center'}
          color="brand.500"
          _hover={{
            color: 'brand.600',
          }}
          as={GatsbyLink}
          to={routes.blog()}
          mb={4}
        >
          <Icon mr={2} as={AiOutlineCaretLeft} /> Blog
        </Link>
        <Heading as="h1" size="lg" mb={1}>
          {title}
        </Heading>
        <BlogPostPublishedAt publishedAt={publishedAt} />
        <BlogPostTags mb={6} tags={tags} />
        {coverImgSrc && (
          <Image
            mb={6}
            borderRadius={'md'}
            src={coverImgSrc}
            alt={coverImgAlt}
            loading={'lazy'}
            width={96}
            maxW={'100%'}
          />
        )}
        <div
          className={styles.blogPostPage}
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <ShareOnSocialMedia />
      </Container>
    </PageLayout>
  );
};

export default BlogPostPage;

export const Head: HeadFC<BlogPostPageProps['data']> = ({ data }) => {
  const { title, slug, summary } = data.markdownRemark.frontmatter;
  const coverImgSrc =
    data.markdownRemark.frontmatter.coverImg?.childImageSharp.gatsbyImageData
      .images.fallback.src;

  return (
    <SEO
      title={`${title} - instatab`}
      ogTitle={title}
      ogUrl={`https://instatab.io/blog/${slug}`}
      ogDescription={summary}
      ogImage={coverImgSrc}
      ogType="article"
    />
  );
};

export const query = graphql`
  query BlogPostPage($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        tags
        publishedAt
        title
        slug
        summary
        coverImg {
          childImageSharp {
            gatsbyImageData
          }
        }
        coverImgAlt
      }
    }
  }
`;
